import React, { Component } from "react";
import { graphql } from "gatsby";
import Empty from "../components/Empty";
import Seo from '../components/Seo/Seo';
import Posts from '../components/Posts';
import 'minireset.css';

export default class Index extends Component {
  render() {
    const { data } = this.props;
    return (
      <Empty>
        <Seo />
        <Posts showAllBtn={false} data={data}/>
      </Empty>
    )
  }
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 20
      sort: { fields: [fields___prefix], order: DESC }
      filter: { frontmatter: { draft: { ne: true } } }
      ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            tags
            image
            date(formatString: "DD/MM/YYYY")
            description
          }
        }
      }
    }
  }
`;
